import * as React from 'react'
import TimeoutModal from '../../components/timeoutModal'
import { useAuth } from '../../hooks/useAuth'
import Layout from '../../layout'
import EventCode from '../../pagesComponents/login/event-code'

const EventCodePage = () => {
	const { resetInVenueOnboarding } = useAuth()

	return (
		<Layout theme="light" title="Account Created">
			<EventCode
				inVenue
				basePath="/in-venue-onboarding"
				backBtn={-1}
				onClose={() => {
					resetInVenueOnboarding()
				}}
			/>
			<TimeoutModal onReset={resetInVenueOnboarding} />
		</Layout>
	)
}

export default EventCodePage
