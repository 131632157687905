import React, { useEffect, useState } from 'react'
import BasePage from '../../components/basePage'
import { Button, Text, Flex, Container, useToast } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
	getTempProfile,
	removeBookingId,
	setTempProfile,
	setTempGroup,
	getEventId,
	setEventId,
	removeEventId,
	setRFID,
	setTempTeam
} from '../../helpers/database'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorToastOptions } from '../../helpers'

import Input from '../../components/input'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'
import {
	createGroup,
	getGroupByEventId,
	getGroupMembers
} from '../../services/group'
// import { getTeamByRfid } from '../../services/team'

const EventCode = ({ basePath = '/login', backBtn, onClose, inVenue }) => {
	const queryClient = useQueryClient()
	const toast = useToast()
	const { profile } = useAuth()
	const [displayOnError, setDisplayOnError] = useState(false)
	const [isFetchin, setIsFetchin] = useState(false)

	const resolver = yupResolver(
		object({
			eventCode: string().trim().required().min(2)
		}).required()
	)
	const {
		watch,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
		setError,
		clearErrors
	} = useForm({
		resolver,
		mode: 'onBlur',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			eventCode: getEventId()
		}
	})

	const watchEventCode = watch('eventCode')
	const isValid = !!watchEventCode
	const storedEventId = getEventId()

	useEffect(() => {
		if (
			typeof storedEventId !== 'undefined' &&
			storedEventId &&
			storedEventId.length > 0
		) {
			//Reset the event id
			onSubmit({ eventCode: storedEventId })
		}
	}, [storedEventId])

	const createGroupMutation = useMutation(createGroup, {
		onSuccess: async (data) => {
			removeEventId()
			eventTracking('group_created', {
				group_id: data?.id
			})

			setTempGroup(data)

			await queryClient.invalidateQueries(['groups'], {
				exact: true
			})
			// Get group members to see if they already have an rfid
			getGroupMembersMutation.mutate({
				queryKey: ['groupId', data?.id]
			})

			// return navigate(`${basePath}/link-rfid`)
		},
		onError: (error) => {
			setIsFetchin(false)
			removeEventId()
			setDisplayOnError(true)
			console.log('[CREATE_GROUP]', error)

			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const getGroupMembersMutation = useMutation(getGroupMembers, {
		onSuccess: async (data) => {
			//TODO: do we need to have tracking in this scenario
			await queryClient.invalidateQueries(['groups'], {
				exact: true
			})

			let matchingUser = (data || []).find((a) => a.id === profile.id)

			if (matchingUser && matchingUser.rfid) {
				// const team = await getTeamByRfid({
				// 	queryKey: ['rfid', matchingUser.rfid]
				// })

				// if (team) {
				// 	setTempTeam(team)
				// }
				// User already has an account
				setRFID({
					shortCode: matchingUser.rfid
				})
				return navigate(`${basePath}/ready-to-play`)
			} else {
				return navigate(`${basePath}/link-rfid`)
			}
		},
		onError: (error) => {
			setDisplayOnError(true)
			setIsFetchin(false)
			removeEventId()
		}
	})

	// const getGroupByEventIdMutation = useMutation(getGroupByEventId, {
	// 	onSuccess: async (data) => {
	// 		const formData = getValues()

	// 		if (data?.id) {
	// 			setTempGroup(data)

	// 			getGroupMembersMutation.mutate({
	// 				queryKey: ['groupId', data?.id]
	// 			})
	// 		} else if (formData?.eventCode) {
	// 			// No group found, so try to create a new one
	// 			createGroupMutation.mutate({ eventId: formData?.eventCode })
	// 		}
	// 	},
	// 	onError: (error) => {
	// 		removeEventId()
	// 		console.log('get group errror', error)
	// 		setDisplayOnError(true)
	// 		toast({
	// 			description: error.message || error,
	// 			...errorToastOptions
	// 		})
	// 	}
	// })

	const onSubmit = async (formData) => {
		// const data = getValues()
		const { eventCode } = formData

		if (eventCode !== 'undefined' && eventCode && eventCode.length > 0) {
			// See if there is an existing group
			setIsFetchin(true)
			createGroupMutation.mutate({
				eventId: formData?.eventCode
			})

			// getGroupByEventIdMutation.mutate({
			// 	queryKey: ['eventId', eventCode]
			// })
		}
	}

	console.log('storedEventid', storedEventId)

	return !storedEventId || storedEventId === 'undefined' ? (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				theme="dark"
				access={1}
				parent="login"
				// backBtn={backBtn}
				// closeBtn={!!onClose}
				// closeBtnText="Exit"
				// onClose={onClose}
				additionalTopPadding
				hideSidebar={inVenue}
				fullWidthActionBtns
				largeTitle={
					inVenue ? (
						<Container py={20}>
							<Text color="white" as="h1" textAlign="center">
								WELCOME TO
							</Text>
							<StaticImage
								width={380}
								alt="Logo"
								style={{ marginBottom: 80 }}
								src="../../images/logo-white.png"
							/>
							<br />
							Enter your
							<br />
							Event Code
						</Container>
					) : (
						<>
							Enter your
							<br />
							Event Code
						</>
					)
				}
				primaryBtn={
					<Container maxW="md" centerContent>
						<Button
							style={{ width: '100%' }}
							variant="primary"
							isLoading={isFetchin}
							disabled={!isValid}
							type="submit"
						>
							Continue
						</Button>
					</Container>
				}
			>
				<Container maxW="md">
					<Input
						name="eventCode"
						type="number"
						variant="filled"
						placeholder="Event code"
						mt="20px"
						register={register}
						errors={errors}
					/>
					<Text
						variant="m"
						color="white"
						textAlign="center"
						mt="10px"
					>
						Tip: Our check-in team will provide your group event
						code.
					</Text>
				</Container>
			</BasePage>
		</form>
	) : (
		<BasePage theme="dark" hideSidebar={inVenue}></BasePage>
	)
}

export default EventCode
